import React, { PureComponent } from 'react';
import { errorValues, hasDisabledStatus, getFieldValue } from './utils';

export default class GenericField extends PureComponent {
  render() {
    const {
      label,
      min,
      type,
      placeholder,
      defaultValue,
      maxLength,
      disabled,
      onChange = null,
      style = null,
      field,
      validateUntouched,
      form: { values, setFieldValue, status },
      value
    } = this.props;
    const { fieldTouched, fieldError, errorClass } = errorValues(this.props);
    const isDisabledStatus = hasDisabledStatus(status, field.name);

    if (type === 'checkbox') {
      return (
        <div className={errorClass}>
          <div className="checkbox">
            {label && (
              <label>
                <input
                  {...field}
                  checked={getFieldValue(values, field.name) === value}
                  onChange={() => setFieldValue(field.name, value)}
                  disabled={disabled || isDisabledStatus}
                  type={type}
                />{' '}
                {label}
              </label>
            )}
          </div>
          <div>
            {(fieldTouched || validateUntouched) && <span className="has-error">{fieldError}</span>}
          </div>
        </div>
      );
    }

    if (type === 'radio') {
      return (
        <span className={errorClass}>
          <label className="radio-inline">
            <input
              {...field}
              disabled={disabled || isDisabledStatus}
              type={type}
              checked={getFieldValue(values, field.name) === value}
              onChange={
                onChange
                  ? () => {
                      // onChange(value);
                      setFieldValue(field.name, value);
                    }
                  : () => setFieldValue(field.name, value)
              }
            />{' '}
            {label}
          </label>
        </span>
      );
    }

    return (
      <div className={errorClass}>
        {label && (
          <label>
            {navigator.userAgent.indexOf('gonative') > -1 && this.props.showEmailControl ? (
              <a href={`tel:${field.value}`}>
                <i className="fa fa-envelope fa-fw" /> Email
              </a>
            ) : (
              label
            )}
          </label>
        )}
        <div>
          <input
            {...field}
            defaultValue={defaultValue}
            disabled={disabled || isDisabledStatus}
            maxLength={maxLength}
            min={min}
            placeholder={placeholder ? placeholder : label}
            type={type}
            value={field.value || ''}
            style={style}
            className="form-control"
            onBlur={() => {
              if(field.name && field.value) {
                setFieldValue(field.name, field.value?.trim())
              }

            }}
          />
          {(fieldTouched || validateUntouched) && fieldError && (
            <span className="has-error">{fieldError}</span>
          )}
        </div>
      </div>
    );
  }
}
